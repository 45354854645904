/*
var prospects = [
    {"id":"","name":"Aspect Communications","salesPerson":"Vaibhav","requirement":"",address:null,comment:"",isCustomer:false,
    contacts:[{name:"Ritesh",designation:"MD",phoneno:"1234567890",email:"test@gmail.com"}],
    items:[
        {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
        "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
    ]},
    {id:"",name:"Datamatics Pvt Ltd",salesPerson:"Prabhav",requirement:"",address:"",comment:"",isCustomer:false,
    contacts:[{name:"Sunit",designation:"Director HR",phoneno:"1234567890",email:"test@gmail.com"}],
    items:[
        {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
        "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
    ]},
    {id:"",name:"Solar Info",salesPerson:"Vaibhav",requirement:"",address:"",comment:"",isCustomer:false,
    contacts:[{name:"Pradeep",designation:"Manager",phoneno:"1234567890",email:"test@gmail.com"}],
    items:[
        {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
        "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
    ]},
];
*/

import {host} from '@/services/appService.js';

export const getProspectTemplate=function(){
    let prospect = {id:"",name:"",salesPerson:"",requirement:"",address:"",comment:"",isCustomer:false,
                    contacts:[{name:"",designation:"",phoneno:"",email:""}],
                    items:[
                        {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
                        "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
                    ]}
    return prospect;
}

export const listProspects= async function(){
    let url = host + "prospect/list";
    return await fetch(url).then(data=> data.json());
}

/*
// Example POST method implementation:
async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

*/
export const addProspect= async function(prospect){
    let payload = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(prospect)
    }
    let url = host + "prospect/add";
    const response = await fetch(url,payload);
    return response.json();
}

export const addContactToProspect = async function(contactDetails){
    let payload = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(contactDetails)
    }
    let url = host + "prospect/addContactToProspect";
    const response = await fetch(url,payload);
    return response.json();
}

export const listContactsOfProspect = async function(prospect){
    let payload = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(prospect)
    }
    let url = host + "prospect/listContactsOfProspect";
    const response = await fetch(url,payload);
    return response.json();
}

export const deleteContactFromProspect = async function(prospect){
    let payload = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(prospect)
    }
    let url = host + "prospect/deleteContactFromProspect";
    const response = await fetch(url,payload);
    return response.json();
}

export const updateProspect=function(pros){
    console.log(pros);
}