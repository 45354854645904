<template>
  <div>
      <div class=" m-2 pt-2 pb-2 bg-secondary text-light">Invoice Management</div>
      <div class="row">
          <div class="col">
              <div class="input-group">
                <div class="w-50"><input type="text" class="form-control form-control-sm" placeholder="Enter Invoice number" aria-label="City"></div>
                <button class="btn btn-outline-primary btn-sm ms-2 " v-on:click="listInvoices">Get</button>
                <button class="btn btn-outline-primary btn-sm ms-2"  v-on:click="newInvoice">New Invoice</button>
              </div>
          </div>
      </div>
      <div class="row">
        <div v-if="showscreen==screens.SEARCH_RESULTS" class="mt-2"> 
            <div class="table-responsive">
              <table class="table table-striped table-hover caption-top">
                  <caption>Search Reasults</caption>
                  <thead>
                      <tr>
                          <th width="10%">Invoice Number</th>
                          <th width="40%">Customer</th>
                          <th width="20%">Date</th>
                          <th width="10%">Amount</th>
                          <th width="20%"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="text-start" v-for="(inv,idx) in invoices" v-bind:key="idx">
                          <td>{{inv.invoice.invNumber}}</td>
                          <td>{{inv.customer.name}}</td>
                          <td>{{inv.invoice.invDate}}</td>
                          <td>{{100}}</td>
                          <td>
                            <div class="col">
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary" v-on:click="showDetails(inv)"><i class="bi bi-card-text"></i></button>
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary"><i class="bi bi-pencil-square"></i></button>
                            </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
        </div>
        <div v-if="showscreen==screens.NEW_INVOICE" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>New Invoice</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <!-- 1st row invoicenumber,invdate,salesperson -->
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Invoice Number</span>
                                    <input type="text" class="form-control" v-model="curInvoice.invoice.invNumber" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group col-6">
                                    <span class="input-group-text" > Invoice Date</span>
                                    <input type="date" class="form-control" v-model="curInvoice.invoice.invDate" placeholder="GST Number">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Sales Person</span>
                                    <input type="text" class="form-control" v-model="curInvoice.invoice.salesPerson" placeholder="Sales Person">
                                </div>
                            </div>
                        </div>
                        <!-- 2nd row Eway bill, purchase order, po date -->
                        <div class="row mt-2">
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text"> E-way Bill No</span>
                                  <input type="text" class="form-control" v-model="curInvoice.invoice.EwayBillNo" placeholder="Sales Person">
                              </div>
                            </div>
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text">Customer P.O</span>
                                  <input type="text" class="form-control" v-model="curInvoice.invoice.PO" placeholder="Purchase Order No">
                              </div>
                            </div>
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text">P.O date</span>
                                  <input type="date" class="form-control" v-model="curInvoice.invoice.PODate">
                              </div>
                            </div>
                        </div>
                        <!-- Customer Details: name, billing address, shipping address -->
                        <div class="row mt-2">
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text"> Customer</span>
                                  <select class="form-select" @change="selectedCustomerDetails($event)">
                                    <option v-for="(customer,index) in customers" v-bind:key="index" v-bind:value="index">
                                        {{customer.name}}
                                    </option>
                                </select>
                              </div>
                              <div class="input-group mt-2">
                                  <span class="input-group-text">Supplier No</span>
                                  <input type="text" class="form-control" v-model="curInvoice.customer.supplierNo">
                              </div>
                            </div>
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text">Billing Address</span>
                                  <textarea class="form-control" v-model="curInvoice.customer.billAddress" rows="5" ></textarea>
                              </div>
                            </div>
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text">Shipping Address</span>
                                  <textarea class="form-control" v-model="curInvoice.customer.shippingAddress" rows="5" ></textarea>
                              </div>
                            </div>
                        </div>
                        <!--Invoice Items -->
                        <div class="d-flex justify-content-start">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="curInvoice.invoice.TAX_SGST">
                                <label class="form-check-label" >SGST</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="curInvoice.invoice.TAX_CGST">
                                <label class="form-check-label" >CGST</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="curInvoice.invoice.TAX_IGST">
                                <label class="form-check-label" >IGST</label>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="table-responsive">
                              <table class="table table-striped table-hover caption-top">
                                  
                                  <thead>
                                      <tr>
                                          <th width="5%">slno</th>
                                          <th width="30%">Description</th>
                                          <th width="10%">Part No</th>
                                          <th width="5%">Qty</th>
                                          <th width="10%">HSN/SAC</th>
                                          <th width="5%">GST</th>
                                          <th width="10%">Unit Price</th>
                                          <th width="5%">Package</th>
                                          <th width="20%">Amount</th>
                                          <th width="20%"></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr class="text-start" v-for="(item,index) in curInvoice.items" v-bind:key="index">
                                          <td>{{index+1}}</td>
                                          <td>{{item.desc}}</td>
                                          <td>{{item.partno}}</td>
                                          <td>{{item.qty}}</td>
                                          <td>{{item.hsn}}</td>
                                          <td>{{item.tgst}}</td>
                                          <td class="text-end">{{item.price}}</td>
                                          <td>{{item.Package}}</td>
                                          <td class="text-end">{{parseInt(parseFloat(item.price)* parseInt(item.qty) * (1+parseFloat(item.tgst)/100))}}</td>
                                          <td class="d-flex justify-content-between">
                                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary"><i class="bi bi-trash"></i></button>
                                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary"><i class="bi bi-pencil"></i></button>
                                          </td>
                                      </tr>
                                  </tbody>
                                  <tfoot >                
                                  </tfoot>
                              </table>
                               <div class="d-flex justify-content-around">
                                    <div>
                                        <button type="button" style="width:80px" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="showProductsToAdd(invoice)"><i class="bi bi-plus-lg"></i><i class="bi bi-box"></i></button>
                                    </div>
                                    <div class="flex-grow-1" > <p>  </p> </div>
                                    <div>
                                        <div class="input-group">
                                            <span class="input-group-text">Total</span>
                                            <input type="text" class="form-control text-end" v-model="curInvoice.invoice.totalAmount">
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <!-- Tax table -->
                        <div class="row mt-2">
                            <div class="col">
                                <p></p>
                            </div>
                            <div class="col">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr v-for="(name,value,index) in curInvoice.tax_table" v-bind:key="index">
                                                <td class="text-start">{{name[0]}}%</td>
                                                <td class="text-end">{{Math.round(name[1])}}</td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                            </div>
                        </div>
                        <!-- Total Amount and Total Tax amount in words -->
                        <div class="row mt-2">
                            <div class="col text-start">
                                Total Amount in words<br>
                                <strong>{{curInvoice.invoice.tAmountInWords}}</strong>
                            </div>
                            <div class="col text-start">
                                Total Tax amount in words<br>
                                <strong>{{curInvoice.invoice.tTaxAmountInWords}}</strong>
                            </div>
                        </div>
                        <!-- HSN Table -->
                        <div class="table-responsive mt-2"> 
                            <table class="table table-striped table-hover" >
                                <thead>
                                    <tr>
                                        <th width="30%">HSN / SAC</th>
                                        <th width="10%">Taxable Value</th>
                                        <th width="10%">SGST Rate</th>
                                        <th width="10%">SGST Value</th>
                                        <th width="10%">CGST Rate</th>
                                        <th width="10%">CGST Value</th>
                                        <th width="10%">IGST Rate</th>
                                        <th width="10%">IGST Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in curInvoice.items" v-bind:key="index">
                                        <td class="text-start">{{item.hsn}}</td>
                                        <td>{{parseInt(item.price * item.qty)}}</td>
                                        <td><span v-if="item.SGST_AMT>0" >{{item.sgst}} %</span></td>
                                        <td class="text-end"><span v-if="item.SGST_AMT>0" >{{parseInt(item.SGST_AMT)}}</span></td>
                                        <td><span v-if="item.CGST_AMT>0" >{{item.cgst}} %</span></td>
                                        <td class="text-end"><span v-if="item.CGST_AMT>0" >{{parseInt(item.CGST_AMT)}}</span></td>
                                        <td><span v-if="item.IGST_AMT>0" >{{item.igst}} %</span></td>
                                        <td class="text-end"><span v-if="item.IGST_AMT>0" >{{parseInt(item.IGST_AMT)}}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">Total</td>
                                        <td>{{curInvoice.invoice.totalTaxableAmount}}</td>
                                        <td></td>
                                        <td class="text-end">{{curInvoice.invoice.totalSGSTAmount}}</td>
                                        <td></td>
                                        <td class="text-end">{{curInvoice.invoice.totalCGSTAmount}}</td>
                                        <td></td>
                                        <td class="text-end">{{curInvoice.invoice.totalIGSTAmount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Payment and terms conditions and comment -->
                        <div class="row mt-2">
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text"> Payment Mode</span>
                                  <input type="text" class="form-control" v-model="curInvoice.paymentMode" placeholder="Mode of Payment">
                              </div>
                            </div>
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text">Comments</span>
                                  <textarea class="form-control" v-model="curInvoice.invComments" rows="5" ></textarea>
                              </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2">Save</button>
                     <button class="btn btn-primary btn-sm ms-2" v-on:click="printScreen">Print Preview</button>
                 </div>
             </div>
        </div>
        <div v-if="showscreen==screens.ADD_PRODUCT" class="mt-2">
            <div class="card">
                 <div class="card-header d-flex justify-content-between">
                     <div><h5>New Invoice : {{curInvoice.invoice.invNumber}}</h5></div>
                     <div class="mr-2"><h5>Dated : {{curInvoice.invoice.invDate}}</h5></div>
                 </div>
                 <div class="card-body">
                     <h6 class="text-start">Add product to the invoice</h6>
                     <quotationItem  @product-selected = "storeProductSelectedDetails"
                        :TAX_CGST=curInvoice.invoice.TAX_CGST :TAX_SGST=curInvoice.invoice.TAX_SGST
                        :TAX_IGST=curInvoice.invoice.TAX_IGST :showProductDetails=false> 
                    </quotationItem>
                    <div class="input-group ms-2">
                        <span class="input-group-text" > Package</span>
                        <input type="text" class="form-control" v-model="productSelected.Package" placeholder="name">
                    </div>
                 </div>
                 <div class="card-footer">
                     <button type="button" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="addProductToInvoice()">Save</button>
                     <button type="button" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="moveToNewInvoiceScreen()">Cancel</button>
                 </div>
            </div>
        </div>
        <div v-show="showscreen==screens.INVISIBLE" class="mt-2" >
            <div style="border-style: solid; font-size:10px" >
                <div id="screenPrint" ref="screenPrint" >
                    <div class="m-2 d-flex justify-content-between">
                        <div><h2 style="text-align: left;">CELLULERA</h2></div>
                        <div><img class="float-right" style="height:50px; width:50px;" src="@/assets/logo1.png"/></div>
                    </div>
                    <!-- Company Details -->
                    <div class="d-flex justify-content-between">
                        <div class="text-start clearfix ms-2">
                            <strong>Cellulera Infotech Pvt. Ltd.</strong><br>
                            No.130, Best County 2, MS Palya<br>
                            Near Sambram College, Vidyaranyapura<br>
                            Bangalore 560097<br>
                        </div>
                        <div class="text-end me-2"> 
                            Phone : 080 23647858<br>
                            Email : info@cellulera.in<br>
                            Website : www.cellulera.in<br>
                        </div>
                    </div>
                    <div class="bg-dark" style="height:2px;" ></div>
                    <!-- Company Tax Details -->
                    <div class="d-flex justify-content-between"> 
                        <div class="text-start clearfix ms-2">
                            <span class="fw-bold">GSTIN</span> : 29AAGCC9860P1ZP <br>
                            <span class="fw-bold">PAN</span> : AAGCC9860P <br>
                            <span class="fw-bold">CIN</span> : U74999KA2017PTC102802
                        </div>
                        <div class="align-self-center"> 
                            <strong><h3>INVOICE</h3></strong>
                        </div>
                        <div class="text-end me-2 align-self-center">
                            Original for Recipient
                        </div>
                    </div>
                    <div class="bg-dark" style="height:2px;" ></div>
                    <!-- Invoice Details -->
                    <div class="d-flex justify-content-between">
                        <div class=" text-start clearfix ms-2" style="max-width: 20%;"> 
                            <span class="fw-bold">Billing Address</span>
                            <div class="fw-bold">{{curInvoice.customer.name}}</div>
                            <div>{{curInvoice.customer.billAddress}}</div>
                        </div>
                        <div class=" text-start ms-2" style="max-width: 20%;"> 
                            <span class="fw-bold">Shipping Address</span>
                            <div>{{curInvoice.customer.shippingAddress}}</div>
                        </div>
                        <div class="ms-2" style="max-width: 40%;"> 
                            <span class="fw-bold">Invoice Details</span>
                            <div class="d-flex justify-content-between">
                                <div class="text-start"> 
                                        <label class="fw-bold">Invoice No</label> {{curInvoice.invoice.invNumber}}<br>
                                        <label class="fw-bold">P.O No</label> {{curInvoice.invoice.PO}}<br>
                                        <label class="fw-bold">E-Way Bill No</label> {{curInvoice.invoice.EwayBillNo}}<br>
                                        <label class="fw-bold">Sales Person</label> {{curInvoice.invoice.salesPerson}}<br>
                                </div>
                                <div class="text-start ms-2">
                                        <label class="fw-bold">Invoice Date</label> {{curInvoice.invoice.invDate}}<br>
                                        <label class="fw-bold">PO Date</label> {{curInvoice.invoice.PODate}}<br>
                                        <label class="fw-bold">Payment Mode</label> {{curInvoice.invoice.paymentMode}}<br>
                                </div>
                            </div>
                        </div>
                        <div class="text-start ms-2 me-2" style="max-width: 20%;"> 
                            <span class="fw-bold">Our Bank</span>
                            <div class="ms-2">
                                    ICICI Bank Limited <br>
                                    Vidyaranyapura Branch,<br>
                                    IFSC Code ICIC0002191<br>
                                    Ac No. 219105001099<br>
                            </div>
                        </div>
                    </div>
                    <div class="bg-dark" style="height:2px;" ></div>
                    <!-- Invoice Items -->
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover caption-top" style="font-size:10px">
                            <thead>
                                <tr>
                                    <th width="5%">slno</th>
                                    <th width="40%">Description</th>
                                    <th width="10%">Part No</th>
                                    <th width="5%">Qty</th>
                                    <th width="10%">HSN/SAC</th>
                                    <th width="5%">GST</th>
                                    <th width="10%">Unit Price</th>
                                    <th width="5%">Package</th>
                                    <th width="20%">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-start" v-for="(item,index) in curInvoice.items" v-bind:key="item.id">
                                    <td>{{index+1}}</td>
                                    <td>{{item.desc}}</td>
                                    <td>{{item.partno}}</td>
                                    <td class="text-center">{{item.qty}}</td>
                                    <td class="text-center">{{item.hsn}}</td>
                                    <td class="text-center">{{item.tgst}}</td>
                                    <td class="text-end">{{item.price}}</td>
                                    <td>{{item.Package}}</td>
                                    <td class="text-end">{{item.Amount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Tax table -->
                    <div class="row mt-2">
                        <div class="col-9">
                            <p></p>
                        </div>
                        <div class="col">
                            <div class="table-responsive" >
                                <table class="table" style="font-size:10px">
                                    <tbody>
                                        <tr v-for="(name,value,index) in curInvoice.tax_table" v-bind:key="index">
                                            <td class="text-start">{{name[0]}}%</td>
                                            <td class="text-end">{{Math.round(name[1])}}</td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                    <!-- Total Amount and Total Tax amount in words -->
                    <div class="row mt-2">
                        <div class="col d-flex justify-content-around">
                            <div>Total Amount in Words : {{curInvoice.invoice.tAmountInWords}}</div>
                            <div>Total Tax amount in words: {{curInvoice.invoice.tTaxAmountInWords}} </div>
                        </div>
                    </div>
                    <!-- HSN Table -->
                    <div class="table-responsive mt-2"> 
                            <table class="table table-striped table-hover" style="font-size:10px">
                                <thead>
                                    <tr>
                                        <th width="30%">HSN / SAC</th>
                                        <th width="10%">Taxable Value</th>
                                        <th width="10%">SGST Rate</th>
                                        <th width="10%">SGST Value</th>
                                        <th width="10%">CGST Rate</th>
                                        <th width="10%">CGST Value</th>
                                        <th width="10%">IGST Rate</th>
                                        <th width="10%">IGST Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in curInvoice.items" v-bind:key="index">
                                        <td class="text-start">{{item.hsn}}</td>
                                        <td>{{parseInt(item.price * item.qty)}}</td>
                                        <td><span v-if="item.SGST_AMT>0" >{{item.sgst}} %</span></td>
                                        <td class="text-end"><span v-if="item.SGST_AMT>0" >{{parseInt(item.SGST_AMT)}}</span></td>
                                        <td><span v-if="item.CGST_AMT>0" >{{item.cgst}} %</span></td>
                                        <td class="text-end"><span v-if="item.CGST_AMT>0" >{{parseInt(item.CGST_AMT)}}</span></td>
                                        <td><span v-if="item.IGST_AMT>0" >{{item.igst}} %</span></td>
                                        <td class="text-end"><span v-if="item.IGST_AMT>0" >{{parseInt(item.IGST_AMT)}}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">Total</td>
                                        <td>{{curInvoice.invoice.totalTaxableAmount}}</td>
                                        <td></td>
                                        <td class="text-end">{{curInvoice.invoice.totalSGSTAmount}}</td>
                                        <td></td>
                                        <td class="text-end">{{curInvoice.invoice.totalCGSTAmount}}</td>
                                        <td></td>
                                        <td class="text-end">{{curInvoice.invoice.totalIGSTAmount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>

                </div>
                <div  ref="screenBottomPrint">
                    <!-- Terms and Condition -->
                    <div class=" text-start clearfix ms-2"> 
                        <span class="fw-bold"><small>Terms and Conditions</small></span>
                        <div> 
                            <ul class="">
                                <li style="font-size:10px">Goods once sold will not be taken back or exchanged. Weights & measures rules are complied by Manufacturer for these products.</li>
                                <li style="font-size:10px">When separate Delivery challan is not attached, then this Invoice is Invoice cum Challan.</li>
                                <li style="font-size:10px">Our Bank Details : Cellulera Infotech Private Limited, Current Account No. 219105001099, ICICI Bank Limited, Vidyaranyapura Branch, IFSC Code ICIC0002191</li>
                                <li style="font-size:10px">24% interest per annum will be charged towards late payment of the due amount after the agreed terms of payment</li>
                                <li style="font-size:10px">All disputes are subject to the jurisdiction of the courts of Bangalore. Manufacturer's Warranty and service terms and conditions apply whenever not mentioned in the product description</li>
                            </ul>
                        </div>
                    </div>
                    <p class="">
                        our CIN No.: U74999KA2017PTC102802
                    </p>
                    <!-- Declaration -->
                    <div class=" text-start clearfix ms-2">
                        <span class="fw-bold">Declaration</span>
                        <div> 
                            We declare that the invoice shows the actual price of the goods described and that all particulars are true and correct.
                        </div>
                    </div>
                    <!-- Signature-->
                    <div class="d-flex text-center">
                        <div class="border border-3 border-top border-start-0 border-bottom-0 border-dark flex-fill">
                            <div>Goods received in good condition</div>
                            <div style="height:50px"></div>
                            <div>Receiver's Signature</div>
                        </div>
                        <div class="border border-3 border-top border-start-0 border-bottom-0 border-end-0 border-dark flex-fill">
                            <div>for Cellulera Infotech Pvt. Ltd.</div>
                            <div style="height:50px"></div>
                            <div>Authorized Signatory</div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="printToScreen" >Print To Printer</button>
            <button type="button" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="moveToNewInvoiceScreen()">Cancel</button>
        </div>
        
      </div>
  </div>
</template>

<script>
//import {ref} from 'vue';
import {listInvoices,getInvoiceTemplate,nextInvoiceNumber} from '@/services/invoiceService.js';
import {listCustomers} from '@/services/customerService.js';
import quotationItem from '@/components/quotationItem.vue';
import {inWords} from '@/services/appService.js';

export default {
  name: 'InvoiceMgmt',
  components:{
    quotationItem
  },
  data(){
      return {
          showscreen:-1,
          invoices:[],
          curInvoice:{},
          screens:{SEARCH_RESULTS:1,NEW_INVOICE:2,INVOICE_DETAILS:3,ADD_CONTACT:4,ADD_PRODUCT:5,INVISIBLE:0},
          customers:[],
          totalTaxAmount:"",
          totalAmount:"",
          productSelected:{
              slno:"1",
              Description:"",
              PartNo:"",
              Qty:0,
              HSN:"",
              GST:"",
              UnitPrice:"",
              Package:"",
              Amount:"",
              prodserialno:"",
              CGST:"0",
              SGST:"0",
              IGST:"0",
              SGST_AMT:0,
              CGST_AMT:0,
              IGST_AMT:0,},
        
      }
  },
  computed:{
      getCustomerName:function(){
          return this.customers[0].name;
      }
  },
  watch:{
      totalgst:function(){
          this.productSelected.GST = this.totalgst;
      }
  },
  created(){
    this.invoices = listInvoices('all');
    this.customers = listCustomers();
    this.curInvoice = getInvoiceTemplate();
  },
  methods:{
      listInvoices:function(){
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      newInvoice:function(){
          this.showscreen=this.screens.NEW_INVOICE;
          this.curInvoice = getInvoiceTemplate();
          this.curInvoice.invoice.invNumber= nextInvoiceNumber();
      },
      showDetails:function(invoice){
          this.curInvoice=invoice;
          // map information is not stored because it is calculated. Hence construct Map object.
          this.curInvoice.tax_table=new Map();
          this.constructTaxDetails();
          this.showscreen=this.screens.NEW_INVOICE;
      },
      showProductsToAdd(){
          this.showscreen= this.screens.ADD_PRODUCT;
      },
      selectedCustomerDetails(event){
          let idx = event.target.value;
          this.curInvoice.customer.name=this.customers[idx].name;
          this.curInvoice.customer.supplierNo = this.customers[idx].supplierNo;
          this.curInvoice.customer.billAddress=this.customers[idx].address;
      },
      constructTaxDetails(){
        for(let i=0;i<this.curInvoice.items.length;i++){
            this.productSelected = this.curInvoice.items[i];
            this.computeAmount();
            let key = "SGST "+this.productSelected.sgst;
            this.updateTax_Table(key,this.productSelected.SGST_AMT);
            key = "CGST "+this.productSelected.cgst;
            this.updateTax_Table(key,this.productSelected.CGST_AMT);
            key = "IGST "+this.productSelected.igst;
            this.updateTax_Table(key,this.productSelected.IGST_AMT);
        }
      },
      moveToNewInvoiceScreen(){
          this.showscreen=this.screens.NEW_INVOICE;
      },
      computeAmount(){
          let amt = parseFloat(this.productSelected.price) * parseFloat(this.productSelected.qty);
          //console.log('amount :' + amt);
          this.productSelected.SGST_AMT = (this.curInvoice.invoice.TAX_SGST)?(amt * parseFloat(this.productSelected.sgst/100)) : 0;
          //console.log('SGST_AMT :' + this.productSelected.SGST_AMT);
          this.productSelected.CGST_AMT = (this.curInvoice.invoice.TAX_CGST)?(amt * parseFloat(this.productSelected.cgst/100)) : 0;
          //console.log('CGST_AMT :' + this.productSelected.CGST_AMT);
          this.productSelected.IGST_AMT = (this.curInvoice.invoice.TAX_IGST)?(amt * parseFloat(this.productSelected.igst/100)) : 0;
          //console.log('TAX_IGST :' + this.productSelected.IGST_AMT);
          this.productSelected.GST = this.totalgst();
          //console.log('GST :' + this.productSelected.GST);
          this.productSelected.Amount = Math.round(amt +( amt * this.productSelected.GST/100));
          //console.log('amount :' + this.productSelected.Amount);
      },
      totalgst:function(){
          let result=0.0;
          //check if proper structured data is available
          if(this.curInvoice.invoice !== undefined){
            if(this.curInvoice.invoice.TAX_CGST){
                result = result + parseFloat(this.productSelected.cgst);
            }
            if(this.curInvoice.invoice.TAX_SGST){
                result = result + parseFloat(this.productSelected.sgst);
            }
            if(this.curInvoice.invoice.TAX_IGST){
                result = result + parseFloat(this.productSelected.igst);
            }
          }
          return result;
      },
      storeProductSelectedDetails:function(event){
          //console.log('responding to product selcted event');
          this.productSelected = event;
          this.computeAmount();
          //console.log(JSON.stringify(this.productSelected));
      },
      addProductToInvoice(){
         let key = "SGST "+this.productSelected.sgst;
         this.updateTax_Table(key,this.productSelected.SGST_AMT);
         key = "CGST "+this.productSelected.cgst;
         this.updateTax_Table(key,this.productSelected.CGST_AMT);
         key = "IGST "+this.productSelected.igst;
         this.updateTax_Table(key,this.productSelected.IGST_AMT);
         this.curInvoice.items.push(this.productSelected);
         this.calculateInvoiceTotal();
         this.showscreen=this.screens.NEW_INVOICE;
      },
      updateTax_Table(key,value){
         let curValue=0;
         if(this.curInvoice.tax_table.size==0){
             this.curInvoice.tax_table.set(key,value);
             return;
         }
        if(this.curInvoice.tax_table.has(key)){
            curValue = this.curInvoice.tax_table.get(key);
            this.curInvoice.tax_table.set(key,curValue + value);
        }
        else{
            this.curInvoice.tax_table.set(key,value);
        }
         
      },
      calculateInvoiceTotal(){
          let totalAmount=0;
          let totalTaxAmount=0;
          let totalTaxableAmount=0;
          let totalCGSTAmount=0;
          let totalSGSTAmount=0;
          let totalIGSTAmount=0;
          for(let i=0;i<this.curInvoice.items.length;i++){
              totalAmount = totalAmount + this.curInvoice.items[i].Amount;
              totalCGSTAmount = totalCGSTAmount + this.curInvoice.items[i].CGST_AMT;
              totalSGSTAmount = totalSGSTAmount + this.curInvoice.items[i].SGST_AMT;
              totalIGSTAmount = totalIGSTAmount + this.curInvoice.items[i].IGST_AMT;
              totalTaxableAmount = totalTaxableAmount + this.curInvoice.items[i].price;
          }
          totalTaxAmount = totalCGSTAmount + totalSGSTAmount + totalIGSTAmount;
          console.log(totalTaxAmount);
          this.curInvoice.invoice.tAmountInWords = inWords(totalAmount);
          this.curInvoice.invoice.tTaxAmountInWords = inWords(totalTaxAmount);
          this.curInvoice.invoice.totalAmount = totalAmount;
          this.curInvoice.invoice.totalTaxAmount = totalTaxAmount;
          this.curInvoice.invoice.totalCGSTAmount= totalCGSTAmount;
          this.curInvoice.invoice.totalSGSTAmount=totalSGSTAmount;
          this.curInvoice.invoice.totalIGSTAmount=totalIGSTAmount;
          this.curInvoice.invoice.totalTaxableAmount=totalTaxableAmount;
      },
      printScreen(){
          console.log(JSON.stringify(this.curInvoice));
          this.showscreen=this.screens.INVISIBLE;
          
      },
      printToScreen(){
          var top = this.$refs.screenPrint;
          var bottom = this.$refs.screenBottomPrint;
          var myWindow = window.open("", "", "width=600,height=400");
          myWindow.document.write('<html><head>');
          myWindow.document.write('</head><body>')
          myWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
          myWindow.document.write('<div style="border-style: solid; font-size:10px;height:297mm;width:210mm" >');
          myWindow.document.write('<div style="height:232mm">');
          myWindow.document.write(top.innerHTML);
          myWindow.document.write('</div>');
          myWindow.document.write(bottom.innerHTML);
          myWindow.document.write('</div>');
          myWindow.document.write('</body></html>');
        
      }
  }
}
</script>
