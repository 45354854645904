<template>
  <div>
    <div class="d-flex flex-wrap ">
        <div class="m-2" >
            <div class="input-group">
                <span class="input-group-text" > Category</span>
                <input class="form-control" list="newProsProdCat" v-model="selectedCat" v-on:change="catChange">
                <datalist id="newProsProdCat">
                    <option v-for="(category,idx) in categories" v-bind:key="idx" v-bind:value="category.cat">
                        {{category.name}}
                    </option>
                </datalist>                                    
            </div>
        </div>
        <div class="m-2" >
            <div class="input-group">
                <span class="input-group-text" > Make</span>
                <input class="form-control" list="newProsProdMake" v-model="selectedMake" v-on:change="makeChange">
                <datalist id="newProsProdMake">
                    <option v-for="(make,idx) in prodMake" v-bind:key="idx">
                        {{make}}
                    </option>
                </datalist>
            </div>
        </div>
        <div class="m-2" >
            <div class="input-group">
                <span class="input-group-text" >Product</span>
                <input list="newProsProdName" class="form-control" v-model="selectedProdName" v-on:change="prodnameChange" placeholder="select product">
                <datalist id="newProsProdName">
                    <option v-for="(prodName,idx) in prodNames" v-bind:key="idx">
                        {{prodName}}
                    </option>
                </datalist>
            </div>
        </div>
        <div class="m-2" >
            <div class="input-group">
                <span class="input-group-text" >Part No</span>
                <input list="newProsPartnumber" class="form-control" v-model="selectedPartNumber" v-on:change="partnoChange" placeholder="select part number">
                <datalist id="newProsPartnumber">
                    <option v-for="(partnumber,idx) in partNumbers" v-bind:key="idx">
                        {{partnumber}}
                    </option>
                </datalist>
            </div>
        </div>
        <div class="m-2">
            <div class="input-group">
                <span class="input-group-text" > ITP</span>
                <input type="text" readonly class="form-control" v-model="selectedProductMRP" placeholder="ITP">
            </div>                
        </div>
    </div>
    <div v-if="showDetails==true" class="text-start"> Details</div>
    <div v-if="showDetails==true" class="row">
        <div class="d-flex flex-wrap">
            <div class="m-2">
                <div class="input-group">
                    <span class="input-group-text" > SGST</span>
                    <input type="text" readonly class="form-control" v-model="selectedProductSGST" placeholder="SGST">
                </div>                
            </div>
            <div class="m-2">
                <div class="input-group">
                    <span class="input-group-text" > CGST</span>
                    <input type="text" readonly class="form-control" v-model="selectedProductCGST" placeholder="CGST">
                </div>                
            </div>
            <div class="m-2">
                <div class="input-group">
                    <span class="input-group-text" > IGST</span>
                    <input type="text" readonly class="form-control" v-model="selectedProductIGST" placeholder="IGST">
                </div>                
            </div>
            <div class="m-2">
                <div class="input-group">
                    <span class="input-group-text" > HSN</span>
                    <input type="text" readonly class="form-control" v-model="selectedProductHSN" placeholder="HSN">
                </div>                
            </div>
            <div class="m-2">
                <div class="input-group">
                    <span class="input-group-text" >Product <br> Specification</span>
                    <textarea class="form-control" readonly v-model="selectedProductDesc" rows="5"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div v-show="showCommandButtons==true">
        <button class="btn  btn-primary btn-sm ms-2" v-on:click="raiseSelectedEvent">Select</button> 
        <button class="btn  btn-primary btn-sm ms-2" v-on:click="raiseCancelEvent">Cancel</button>
    </div>
</div>
</template>

<script>

export default {
    name:"selectProdComponent",
    inject:['collections'],
    emits:['productSelected','productCancelled','noproductSelected'],
    props: {
        showDetails: {
            type:Boolean,
            default: false
        },
        showCommandButtons: {
            type:Boolean,
            default: false
        },
        title:String,
    },
    data(){
      return {
        categories:[],
        rawProducts:[],
        products:[],
        prodMake:[],
        prodNames:[],
        partNumbers:[],
        selectedCat:"",
        selectedMake:"",
        selectedProdName:"",
        selectedPartNumber:"",
        selectedProductDesc:"",
        selectedProductSGST:"",
        selectedProductCGST:"",
        selectedProductIGST:"",
        selectedProductHSN:"",
        selectedProductMRP:"",
      }
    },
    created(){
       this.categories= this.collections.categories;
       this.rawProducts = this.collections.products;
    },
    watch:{
        products:function(){
            if(this.products.length==1){
                this.selectedProductDesc = this.products[0].desc;
                this.selectedPartNumber = this.products[0].partno;
                this.selectedProductSGST= this.products[0].sgst;
                this.selectedProductCGST= this.products[0].cgst;
                this.selectedProductIGST= this.products[0].igst;
                this.selectedProductHSN= this.products[0].hsn;
                this.selectedProductMRP= this.products[0].mrp;
                if(this.showCommandButtons==false){
                    this.raiseSelectedEvent();
                }
            }else{
                this.selectedProductDesc = "";
                this.selectedProductMRP="0";
                this.raiseNoproductSelectedEvent();
            }
        }
    },
    methods:{
        refreshProdMakes:function(){
          this.prodMake = this.getDistinctProdMakes();
      },
      refreshProdNames:function(){
          this.prodNames =this.getDistinctProdNames();
      },
      refreshPartNumbers:function(){
          this.partNumbers= this.getDistinctProductNumbers();
      },
      getDistinctProdMakes:function(){
          return [ ...new Set(this.products.map(prod => prod.make))];
      },
      getDistinctProdNames:function(){
          return [ ...new Set(this.products.map(prod => prod.pname))];
      },
      getDistinctProductNumbers:function(){
          return [ ...new Set(this.products.map(prod => prod.partno))];
      },
      catChange:function(){
          if(this.selectedCat!=""){
            this.filterProductsByCat(this.selectedCat);
          }else{
            this.selectedMake="";
            this.selectedProdName="";
            this.selectedPartNumber="";
            this.products= this.rawProducts;
          }
          this.refreshProdNames();
          this.refreshPartNumbers();
          this.refreshProdMakes();
      },
      makeChange:function(){
          if(this.selectedMake!=""){
            this.filterProductsByMake(this.selectedMake);
          }else{
            this.products= this.rawProducts;
          }
          this.refreshProdNames();
          this.refreshPartNumbers();
      },
      prodnameChange:function(){
          if(this.selectedProdName!=""){
            this.filterProductsByNames(this.selectedProdName);
          }else{
            this.products= this.rawProducts;
          }
          this.refreshProdMakes();
          this.refreshPartNumbers();
      },
      partnoChange:function(){
          if(this.selectedPartNumber!=""){
            this.filterProductsByPartnumber(this.selectedPartNumber);
          }else{
            this.products= this.rawProducts;
          }
          this.refreshProdNames();
          this.refreshProdMakes();
      },
      filterProductsByMake:function(maker){
          this.products = this.rawProducts.filter(product => product.make==maker);
      },
      filterProductsByNames:function(prodName){
          this.products = this.rawProducts.filter(product => product.pname==prodName);
      },
      filterProductsByPartnumber:function(partnumber){
          this.products = this.rawProducts.filter(product => product.partno==partnumber);
      },
      filterProductsByCat:function(category){
          this.products = this.rawProducts.filter(product => product.pcat==category);
      },
      raiseSelectedEvent:function(){
          let data ={
                cat:this.selectedCat,
                make:this.selectedMake,
                name:this.selectedProdName,
                partno:this.selectedPartNumber,
                desc:this.selectedProductDesc,
                sgst:this.selectedProductSGST,
                cgst:this.selectedProductCGST,
                igst:this.selectedProductIGST,
                hsn:this.selectedProductHSN,
                mrp:this.selectedProductMRP
          }
          this.$emit('productSelected',data);
      },
      raiseCancelEvent:function(){
          this.$emit('productCancelled');
      },
      raiseNoproductSelectedEvent:function(){
          this.$emit('noproductSelected');
      }
    }

}
</script>