import { reactive } from "vue";

export const invoices = reactive(
   [    
        {"id":"",
        "quotation":{"quoteno":"","quotedt":"","salesPerson":"","requirement":"","validityTerm":""},
        "invoice":{"invNumber":1,"invDate":"2022-01-07","salesPerson":"Gundappa","EwayBillNo":"293847l","PO":"po948kw",
                    "PODate":"2022-01-04","custID":"","paymentMode":"","invTermsConditions":"","invComments":"",
                    "tTaxAmountInWords":"thirty two thousand two hundred ","tAmountInWords":"one lakh twenty seven thousand two hundred ",
                    "TAX_SGST":true,"TAX_CGST":true,"TAX_IGST":false,"totalAmount":127200,"totalTaxableAmount":95000,"totalTaxAmount":32200,
                    "totalCGSTAmount":16100,"totalSGSTAmount":16100,"totalIGSTAmount":0},
        "customer":{"name":"Titan Industries","billAddress":"93/2 , Behind Sharada Talkies, SP Rd, Bangalore",
                    "shippingAddress":"93/2 , Behind Sharada Talkies, SP Rd, Bangalore","gst":"","supplierNo":"5465"},
        "contacts":[{"name":"contact1","designation":"Design1","phoneno":"1234567890","email":"test@gmail.com"}],
        "items":[{"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 16GB","partno":"icc-c782519win8",
                    "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"66989.99","qty":1,"tgst":36,"price":70000,
                    "SGST_AMT":12600,"CGST_AMT":12600,"IGST_AMT":0,"GST":36,"Amount":95200,"Package":"Nos"},
                 {"cat":"ACC","make":"HP","name":"HP LaserJet Pro M126nw","partno":"235991",
                    "desc":"HP LaserJet Pro M126nw Wireless Black & White Multi-Function Laserjet Printer (Wireless Direct Printing, CZ175A, Black)",
                    "sgst":"14","cgst":"14","igst":"14","hsn":"84713746","mrp":"21329.00","qty":1,"tgst":28,"price":25000,
                    "SGST_AMT":3500.0000000000005,"CGST_AMT":3500.0000000000005,"IGST_AMT":0,"GST":28,"Amount":32000,"Package":"Nos"}
                ],
        "tax_table":{},
        "paymentMode":"Payment within 1 week of delivery","invComments":"Courier charges extra"},
        {
        id:"",
        quotation:{quoteno:"124",quotedt:"",salesPerson:"",requirement:"",validityTerm:""},
        invoice:{invNumber:"101",invDate:"03-01-2022",salesPerson:"Prabhath",EwayBillNo:"",PO:"",PODate:"",custID:"",
                paymentMode:"",invTermsConditions:"",invComments:"",tTaxAmountInWords:"",tAmountInWords:"",
                TAX_SGST:false,TAX_CGST:false,TAX_IGST:false,totalAmount:0,totalTaxableAmount:0,totalTaxAmount:0,
                totalCGSTAmount:0,totalSGSTAmount:0
                },
        customer:{name:"Hexware",billAddress:"",shippingAddress:"",gst:"",supplierNo:""},
        contacts:[{name:"contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
        items:
            [
            ],
        tax_table:new Map(),
        },
        {
        id:"",
        quotation:{quoteno:"125",quotedt:"",salesPerson:"",requirement:"",validityTerm:""},
        invoice:{invNumber:"102",invDate:"03-01-2022",salesPerson:"",EwayBillNo:"",PO:"",PODate:"",custID:"",
                paymentMode:"",invTermsConditions:"",invComments:"",tTaxAmountInWords:"",tAmountInWords:"",
                TAX_SGST:false,TAX_CGST:false,TAX_IGST:false,totalAmount:0,totalTaxableAmount:0,totalTaxAmount:0,
                totalCGSTAmount:0,totalSGSTAmount:0
                },
        customer:{name:"Mercury Autolabs",billAddress:"",shippingAddress:"",gst:"",supplierNo:""},
        contacts:[{name:"contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
        items:
            [
            ],
        tax_table:new Map(),
        },

        
    ]
)

export const getInvoiceTemplate=function(){
    let invoice = {
                    id:"",
                    quotation:{quoteno:"",quotedt:"",salesPerson:"",requirement:"",validityTerm:""},
                    invoice:{invNumber:"",invDate:"",salesPerson:"",EwayBillNo:"",PO:"",PODate:"",custID:"",
                            paymentMode:"",invTermsConditions:"",invComments:"",tTaxAmountInWords:"",tAmountInWords:"",
                            TAX_SGST:false,TAX_CGST:false,TAX_IGST:false,totalAmount:0,totalTaxableAmount:0,totalTaxAmount:0,
                            totalCGSTAmount:0,totalSGSTAmount:0
                            },
                    customer:{name:"",billAddress:"",shippingAddress:"",gst:"",supplierNo:""},
                    contacts:[{name:"contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
                    items:
                        [
                        ],
                    tax_table:new Map(),
                };
    return invoice;
}

export const listInvoices=function(filterBy){
    filterBy = filterBy +"none";
    console.log(filterBy);
    return invoices;
}

var invoicenumber=1;

export const nextInvoiceNumber = function(){
    return invoicenumber;
}

export const addInvoice=function(invoice){
    invoices.push(invoice);
    invoicenumber++;
}


