<template>
    <!-- Select Product -->
    <selectProdComponent :showDetails=showProductDetails 
        :showCommandButtons=selectProdComponent_showCommandButtons
        @product-cancelled="cancelEventHandler" 
        @product-selected="recordSelectedEventHandler"
        @noproduct-selected="norecordSelectedEventHandler">
    </selectProdComponent>
    <div v-if="isProductSelected == true" class="d-flex flex-wrap">
        <div class="m-2">
            <div class="input-group">
                <span class="input-group-text" >Quantity</span>
                <input type="number" min="1" class="form-control" v-model="selectedProductQty" v-on:change="raiseProductSelectedEvent">
            </div>                
        </div>
        <div class="m-2">
            <div class="input-group">
                <span class="input-group-text" >Total GST</span>
                <input type="number" readonly class="form-control" v-model="selectedProductGST">
            </div>                
        </div>
        <div class="m-2">
            <div class="input-group">
                <span class="input-group-text" >Our Unit Price</span>
                <input type="number" min="selectedProduct.mrp" class="form-control" v-model="selectedProductQPrice" v-on:change="raiseProductSelectedEvent">
            </div>                
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import selectProdComponent from '@/components/productSelection.vue'

export default {
    name: 'quotationItem',
    components:{
        selectProdComponent
    },
    emits:['productSelected','productCancelled','noproductSelected'],
    props: {
        TAX_CGST: {
            type:Boolean,
            default: true
        },
        TAX_SGST: {
            type:Boolean,
            default: false
        },
        TAX_IGST: {
            type:Boolean,
            default: false
        },
        showProductDetails: {
            type:Boolean,
            default: false
        }
    },
    data(){
        return {
           selectedProduct:{
            cat: "ENTERPRISE",
            cgst: "18",
            desc: "",
            hsn: "84713010",
            igst: "18",
            make: "DELL",
            mrp: "79,989.99",
            name: "Inspiron 15 Laptop",
            partno: "icc-c783511win8",
            sgst: "18"},
          isProductSelected:false,
          selectedProductQty:0,
          selectedProductGST:0,
          selectedProductQPrice:0,
          selectProdComponent_showCommandButtons:false
        }
    },
    methods:{
      cancelEventHandler:function(){
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      recordSelectedEventHandler:function(event){
         this.selectedProduct = event;
         this.isProductSelected = true;
         this.selectedProductQty=1;
         this.selectedProductGST=this.getTotalGST();
         this.selectedProductQPrice=this.selectedProduct.mrp;
         this.raiseProductSelectedEvent();
      },
      norecordSelectedEventHandler:function(){
         this.isProductSelected = false; 
         this.selectedProductQty=0;
         this.selectedProductGST=0;
         this.selectedProductQPrice=0;
      },
      getTotalGST:function(){
          let totalGST=0;
          if(this.TAX_CGST==true){
              totalGST = totalGST + parseFloat(this.selectedProduct.cgst);
          }
          if(this.TAX_SGST==true){
              totalGST = totalGST + parseFloat(this.selectedProduct.sgst);
          }
          if(this.TAX_IGST==true){
              totalGST = totalGST + parseFloat(this.selectedProduct.igst);
          }
          return totalGST;
      },
      updateSelectedProductGST(){
          this.selectedProductGST=this.getTotalGST();
      },
      raiseProductSelectedEvent(){
        if(this.selectedProductQPrice>=0){
            let dataToEmit = this.selectedProduct;
            dataToEmit.qty = this.selectedProductQty;
            dataToEmit.tgst = this.selectedProductGST;
            dataToEmit.price = this.selectedProductQPrice;
            this.$emit('productSelected',dataToEmit);
        }
      }
    }
}
</script>