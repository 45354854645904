import { reactive } from "vue";
export var quotations = reactive(
    [
        { id:"1",
        quotation:{quoteno:"CIPL/BLR.20211209/14",quotedt:"09-12-2021",salesPerson:"David",requirement:"",validityTerm:"",
            totalQAmount:"",totalQAmountInWords:"",paymentTerms:"",delivery:""},
        customer:{name:"Hexaware Technologies Ltd",billAddress:"Bangalore",shippingAddress:"",gst:"",supplierNo:"",comment:""},
        contacts:
        [
            {name:"Mr.Kalai Arasan",designation:"MD",phoneno:"9845012345",email:"Kalai@gmail.com"},
            {name:"Mr.Shankar",designation:"MD",phoneno:"9845012345",email:"Shankar@gmail.com"}
        ],
        items:
        [
            {"cat":"","make":"","name":"","partno":"","desc":"","sgst":"","cgst":"","igst":"","hsn":"","mrp":"",
            "qty":1,"price":"","tgst":""} 
        ]
        },
        { id:"2",
        quotation:{quoteno:"CIPL/BLR.20211209/20",quotedt:"29-12-2021",salesPerson:"Prabhu",requirement:"",validityTerm:"",
            totalQAmount:"",totalQAmountInWords:"",paymentTerms:"",delivery:""},
        customer:{name:"TCS-Chennai",billAddress:"Chennai, TN",shippingAddress:"",gst:"",supplierNo:"",comment:""},
        contacts:
        [
            {name:"Mr.Dorai",designation:"GM",phoneno:"9880181118",email:"Dorai$tcs.com"}
        ],
        items:
        [
            {"cat":"","make":"","name":"","partno":"","desc":"","sgst":"","cgst":"","igst":"","hsn":"","mrp":"",
            "qty":1,"price":"","tgst":""} 
        ]
        },

    ]
)

export const getQuotationProductTemplate = function(){
    let prodQuotation ={"cat":"","make":"","name":"","partno":"","desc":"","sgst":"","cgst":"","igst":"","hsn":"","mrp":"",
                      "qty":1,"price":"","tgst":""};
    return prodQuotation;
}

export const getQuotationTemplate = function(){
    let quotation = { id:"",
        quotation:{quoteno:"",quotedt:"",salesPerson:"",requirement:"",validityTerm:"",totalQAmount:"",totalQAmountInWords:"",
                paymentTerms:"",delivery:"",other:""},
        customer:{name:"",billAddress:"",shippingAddress:"",gst:"",supplierNo:"",comment:""},
        contacts:[
            {name:"",designation:"",phoneno:"",email:""}
        ],
        items:[
            {"cat":"","make":"","name":"","partno":"","desc":"","sgst":"","cgst":"","igst":"","hsn":"","mrp":"",
            "qty":1,"price":""} 
        ]
    };
    return quotation;
}

export const listQuotations = function(){
    return quotations;
}

var quotationnumber=0;

export const addQuotation=function(quotation){
    quotationnumber ++;
    quotations.push(quotation);
}

export const nextQuotationNumber = function(){
    return quotationnumber+1;
}