<template>
  <div>
    <div class=" m-2 pt-2 pb-2 bg-secondary text-light">Quotation Management</div>
    <div class="row">
        <div class="col">
            <div class="input-group">
            <div class="w-50"><input type="text" class="form-control form-control-sm" placeholder="Enter Quotation Number" aria-label="City"></div>
            <button class="btn btn-outline-primary btn-sm ms-2 " v-on:click="listQuotations">Get</button>
            <button class="btn btn-outline-primary btn-sm ms-2"  v-on:click="newQuotation">New Quotation</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div v-if="showscreen==screens.SEARCH_RESULTS" class="mt-2"> 
            <div class="table-responsive">
              <table class="table table-striped table-hover caption-top">
                  <caption>Search Reasults</caption>
                  <thead>
                      <tr>
                        <th>Name</th>
                        <th>Quotation Number</th>
                        <th>Date</th>
                        <th>Sales Person</th>
                        <th></th>
                      </tr> 
                  </thead>
                  <tbody>
                      <tr v-for="item in quotations" v-bind:key="item.id">
                        <td class="text-start">{{item.customer.name}}</td>
                        <td>{{item.quotation.quoteno}}</td>
                        <td>{{item.quotation.quotedt}}</td>
                        <td>{{item.quotation.salesPerson}}</td>
                        <td>
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary" v-on:click="showDetails(item)"><i class="bi bi-card-text"></i></button>
                        </td>
                      </tr>
                  </tbody>
              </table>
            </div>
        </div>
        <div v-if="showscreen==screens.NEW_QUOTATION" class="mt-2"> 
            <div class="card">
                 <div class="card-header">
                     <h3>Quotation Details</h3>
                 </div>
                 <div class="card-body">
                     <form >
                         <!-- Quotation Number, Date -->
                         <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Quotation Number</span>
                                    <input type="text" class="form-control" v-model="curQuote.quotation.quoteno" placeholder="number">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Date </span>
                                    <input type="date" class="form-control" v-model="curQuote.quotation.quotedt" >
                                </div>
                            </div>
                         </div>
                         <!-- Customer Details-->
                         <div class="row mt-2">
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text"> Customer</span>
                                  <select class="form-select" v-model="curQuote.customer.name"  @change="selectedCustomerDetails($event)">
                                    <option v-for="(customer,index) in customers" v-bind:key="index" v-bind:value="customer.name">
                                        {{customer.name}}
                                    </option>
                                  </select>
                              </div>
                              <div class="input-group mt-2">
                                    <span class="input-group-text" >Sales Person</span>
                                    <input type="text" class="form-control" v-model="curQuote.quotation.salesPerson" placeholder="">
                              </div>
                            </div>
                            <div class="col">
                              <div class="input-group">
                                  <span class="input-group-text">Billing Address</span>
                                  <textarea class="form-control" v-model="curQuote.customer.billAddress" rows="5" ></textarea>
                              </div>
                            </div>
                         </div>
                         <!--Quotation Tax Items -->
                        <div class="d-flex justify-content-between mt-2">
                            <div>
                                <button type="button" style="width:80px" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="addQuotationItem()"><i class="bi bi-plus-lg"></i><i class="bi bi-box"></i></button>
                            </div>
                            <div> 
                                Select Tax Components :
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" @change="updateSelectedProductGST" v-model="TAX_SGST">
                                    <label class="form-check-label" >SGST</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" @change="updateSelectedProductGST" v-model="TAX_CGST">
                                    <label class="form-check-label" >CGST</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" @change="updateSelectedProductGST" v-model="TAX_IGST">
                                    <label class="form-check-label" >IGST</label>
                                </div>
                            </div>
                        </div>
                        <!--Quotation Product Items -->
                        <div class="table-responsive">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>ITP</th>
                                        <th>Unit Price w/o tax</th>
                                        <th>Tax Rate</th>
                                        <th>Unit Price with tax</th>
                                        <th>Total Price w/o tax</th>
                                        <th>Our Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in curQuote.items" v-bind:key="index">
                                        <td class="text-start">{{item.name}}</td>
                                        <td class="text-end">{{item.qty}}</td>
                                        <td class="text-end">{{item.mrp}}</td>
                                        <td class="text-end">{{item.price}}</td>
                                        <td class="text-end">{{item.tgst}}</td>
                                        <td class="text-end">{{Math.round(parseFloat(item.price) + (parseFloat(item.price) * parseFloat(item.tgst) /100)) }} </td>
                                        <td class="text-end">{{Math.round(parseFloat(item.price) * parseInt(item.qty))}}</td>
                                        <td class="text-end">{{Math.round((parseFloat(item.price) + (parseFloat(item.price) * parseFloat(item.tgst) /100))*parseInt(item.qty))}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-end">
                                <div> 
                                    <div class="input-group">
                                        <span class="input-group-text" > Total</span>
                                        <input type="text" readonly class="form-control text-end" placeholder="number" v-model="curQuote.quotation.totalQAmount">
                                    </div>
                                </div>
                            </div>
                        </div>
                     </form>
                     <div class="row mt-2">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text" > Total in words</span>
                                <input type="text" class="form-control" placeholder="number" v-model="curQuote.quotation.totalQAmountInWords">
                            </div>
                        </div>
                     </div>
                     <div class="row mt-2">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text" > Payment Terms</span>
                                <input type="text" class="form-control" v-model="curQuote.quotation.paymentTerms">
                            </div>
                        </div>
                     </div>
                     <div class="row mt-2">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text" > Validity</span>
                                <input type="text" class="form-control" v-model="curQuote.quotation.validityTerm">
                            </div>
                        </div>
                     </div>
                     <div class="row mt-2">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text" > Delivery</span>
                                <input type="text" class="form-control" v-model="curQuote.quotation.delivery">
                            </div>
                        </div>
                     </div>
                     <div class="row mt-2">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text" > Other</span>
                                <input type="text" class="form-control" v-model="curQuote.quotation.other">
                            </div>
                        </div>
                     </div>
                     <p class="text-start mt-2"> <strong>Terms & Conditions:</strong></p>
                     <ol class="text-start" >
                         <li>Taxes: Any changes in Govt. duties, taxes, levies, surcharge etc. will be charged accordingly at the time of Billing</li>
                         <li>Payment Terms: {{curQuote.quotation.paymentTerms}}</li>
                         <li>Validity: {{curQuote.quotation.validityTerm}}</li>
                         <li>Delivery: {{curQuote.quotation.delivery}}</li>
                         <li>Purchase Order: Purchase Orders once processed cannot be cancelled. All prices quoted above are in Indian Rupees [INR] and your Purchase Order has to be placed on Cellulera Infotech Pvt Ltd.</li>
                         <li>Our GST No. is 29AAGCC9860P1ZP, State Code : 29, State : Karnataka.</li>
                         <li v-if="curQuote.quotation.other!=''">{{curQuote.quotation.other}}</li>
                     </ol>
                 </div>
                 <div class="card-footer">
                     <button class="btn btn-outline-primary ms-2" v-on:click="saveNewQuotation">Save</button>
                     <button class="btn btn-outline-primary ms-2" v-on:click="gotoSearchResultsScreen">Cancel</button>
                     <button v-if="enablePrintPreview==true"  class="btn btn-outline-primary ms-2" v-on:click="showPrintPreview">Print Preview</button>
                     <button v-if="enablePrintPreview==true"  class="btn btn-outline-primary ms-2" v-on:click="generateInvoice">Gen Invoice</button>
                 </div>
            </div>
        </div>
        <div v-if="showscreen==screens.QUOTATION_DETAILS" class="mt-2"> 
        </div>
        <div v-if="showscreen==screens.ADD_CONTACT" class="mt-2"> 
        </div>
        <div v-if="showscreen==screens.ADD_PRODUCT" class=" d-flex mt-2"> 
            <div class="card">
                 <div class="card-header">
                     <h3>New Quotation</h3>
                 </div>
                 <div class="card-body">
                     <div class="text-start">Add product item</div>
                    <!-- Add quation Items-->
                    <quotationItem @product-selected = "storeProductSelectedDetails" 
                    :TAX_CGST="TAX_CGST" :TAX_SGST="TAX_SGST" :TAX_IGST="TAX_IGST"> ></quotationItem>
                 </div>
                 <div class="card-footer">
                     <button class="ms-2 btn btn-sm btn-outline-primary" v-on:click="addProdToQuotationItemList">Save</button>
                     <button class="ms-2 btn btn-sm btn-outline-primary" v-on:click="goToNewQuotation">Cancel</button>
                 </div>
            </div>
        </div>
        <div v-if="showscreen==screens.PRINT_PREVIEW" class="mt-1">
            <div id="quotationScreenPrint" ref="quotationScreenPrint" >
                <div class="m-2 d-flex justify-content-between">
                    <div class="text-start clearfix ms-2">
                        <strong>Cellulera Infotech Pvt. Ltd.</strong><br>
                            No.130, Best County 2, MS Palya<br>
                            Near Sambram College, Vidyaranyapura<br>
                            Bangalore 560097<br>
                            Phone : 080 23647858<br>
                            Email : info@cellulera.in
                    </div>
                    <div class="me-2">
                        <img class="float-right" style="height:100px; width:100px;" src="@/assets/logo1.png"/>
                    </div>
                </div>
                <div class="d-flex justify-content-between"> 
                    <div> 
                        <strong>Qtn Ref : {{curQuote.quotation.quoteno}}</strong>
                    </div>
                    <div> 
                        <strong>Date : {{curQuote.quotation.quotedt}} </strong>
                    </div>
                </div>
                <div class="m-2 text-start">
                    To,<br>
                    {{curQuote.customer.name}}<br>
                    {{curQuote.customer.billAddress}}
                </div>
                <div class="m-2 text-start">
                    Respected Sir,
                </div>
                <div class="m-2 text-center">
                    <strong>Sub: Quotation for products</strong>
                </div>
                <div class="m-2 text-start">
                    Thank you for your valuable enquiry.  Please find the quotation below for the same. 
                </div>
                <div class="table-responsive m-2">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>ITP</th>
                                <th>Unit Price w/o tax</th>
                                <th>Tax Rate</th>
                                <th>Unit Price with tax</th>
                                <th>Total Price w/o tax</th>
                                <th>Our Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in curQuote.items" v-bind:key="index">
                                <td class="text-start">{{item.name}}</td>
                                <td class="text-end">{{item.qty}}</td>
                                <td class="text-end">{{item.mrp}}</td>
                                <td class="text-end">{{item.price}}</td>
                                <td class="text-end">{{item.tgst}}</td>
                                <td class="text-end">{{Math.round(parseFloat(item.price) + (parseFloat(item.price) * parseFloat(item.tgst) /100)) }} </td>
                                <td class="text-end">{{Math.round(parseFloat(item.price) * parseInt(item.qty))}}</td>
                                <td class="text-end">{{Math.round((parseFloat(item.price) + (parseFloat(item.price) * parseFloat(item.tgst) /100))*parseInt(item.qty))}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-end">
                        <div> 
                            Total : {{curQuote.quotation.totalQAmount}}
                        </div>
                    </div>
                </div>
                <div class="m-2 text-start"> 
                    {{curQuote.quotation.totalQAmountInWords}}
                </div>
                <div> 
                    <p class="text-start mt-2 text-decoration-underline"> <strong>Terms & Conditions:</strong></p>
                     <ol class="text-start" >
                         <li>Taxes: Any changes in Govt. duties, taxes, levies, surcharge etc. will be charged accordingly at the time of Billing</li>
                         <li>Payment Terms: {{curQuote.quotation.paymentTerms}}</li>
                         <li>Validity: {{curQuote.quotation.validityTerm}}</li>
                         <li>Delivery: {{curQuote.quotation.delivery}}</li>
                         <li>Purchase Order: Purchase Orders once processed cannot be cancelled. All prices quoted above are in Indian Rupees [INR] and your Purchase Order has to be placed on Cellulera Infotech Pvt Ltd.</li>
                         <li>Our GST No. is 29AAGCC9860P1ZP, State Code : 29, State : Karnataka.</li>
                         <li v-if="curQuote.quotation.other!=''">{{curQuote.quotation.other}}</li>
                     </ol>
                </div>
                <div class="m-2 text-start">
                    <p>We assure you the best support always.</p>
                    <p>
                        Thanking you, 
                    </p>
                    <div style="height: 50px;"> 

                    </div>
                    <p>
                        Warm Regards,<br>
                        Sukanya <br>
                        Mobile : 9036010712 <br>
                        Email : sukanya@cellulera.in 
                    </p>
                </div>
            </div>
            <button type="button" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="printToScreen" >Print To Printer</button>
            <button type="button" class="ms-2 mt-2 btn btn-sm btn-outline-primary" v-on:click="gotoDetailsScreen()">Cancel</button>
        </div>
    </div>
  </div>
</template>

<script>
 // @ is an alias to /src
import quotationItem from '@/components/quotationItem.vue'
import {getQuotationTemplate,listQuotations,nextQuotationNumber,addQuotation} from '@/services/quotationService.js'
import {listCustomers} from '@/services/customerService.js'
import {inWords} from '@/services/appService.js'
import {getInvoiceTemplate,nextInvoiceNumber,addInvoice} from '@/services/invoiceService.js';

export default {
  name: 'QuotationMgmt',
  components:{
      quotationItem
  },
  data(){
      return {
          showscreen:1,
          screens:{QUOTATION_HOME:0,SEARCH_RESULTS:1,NEW_QUOTATION:2,QUOTATION_DETAILS:3,ADD_CONTACT:4,ADD_PRODUCT:5,PRINT_PREVIEW:6},
          quotations:[],
          customers:[],
          totalAmount:"",
          curQuote:{},
          TAX_CGST:false,
          TAX_SGST:true,
          TAX_IGST:false,
          enablePrintPreview:false,
          newProdDetailsToAdd:{}
        }
    },
    created(){
        this.customers = listCustomers();
    },
    methods:{
      listQuotations:function(){
          this.quotations= listQuotations();
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      gotoSearchResultsScreen:function(){
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      showDetails:function(item){
          this.curQuote = item;
          this.enablePrintPreview = true;
          this.showscreen= this.screens.NEW_QUOTATION;
      },
      newQuotation:function(){
          this.enablePrintPreview = false;
          this.showscreen= this.screens.NEW_QUOTATION;
          this.curQuote= getQuotationTemplate();
          this.curQuote.quotation.quoteno=nextQuotationNumber();
          this.curQuote.quotation.validityTerm = "VALID for 2 days from date of quote";
          this.curQuote.quotation.paymentTerms="100% advance along with PO/mail confirmation.";
          this.curQuote.quotation.delivery="-2-3 working days from the date of order if confirmed today and later based on availability.  Price is inclusive of courier charges";
          this.curQuote.quotation.other="";
          this.curQuote.items=[];
      },
      selectedCustomerDetails(event){
          let idx = event.target.selectedIndex;
          this.curQuote.customer.billAddress= this.customers[idx].address;
      },
      saveNewQuotation:function(){
          addQuotation(this.curQuote);
          this.curQuote={};
          this.listQuotations();
      },
      updateSelectedProductGST:function(){
          console.log('update Selected Product GST');
      },
      addQuotationItem:function(){
          this.showscreen= this.screens.ADD_PRODUCT;
      },
      goToNewQuotation:function(){
          this.showscreen= this.screens.NEW_QUOTATION;
      },
      storeProductSelectedDetails(event){
          this.newProdDetailsToAdd = event;
      },
      addProdToQuotationItemList(){
          this.curQuote.items.push(this.newProdDetailsToAdd);
          this.showscreen= this.screens.NEW_QUOTATION;
          this.calculateTotalQAmount();
      },
      calculateTotalQAmount(){
          let totalQAmount =0;
          for(var i=0; i< this.curQuote.items.length;i++){
              let item = this.curQuote.items[i];
              let total = parseFloat(item.price) + (parseFloat(item.price) * parseFloat(item.tgst) /100);
              totalQAmount = totalQAmount + Math.round(total*parseInt(item.qty));
          }
          this.curQuote.quotation.totalQAmount = totalQAmount;
          this.curQuote.quotation.totalQAmountInWords = inWords(totalQAmount);
          console.log(this.curQuote.quotation.totalQAmountInWords);
      },
      showPrintPreview(){
          this.showscreen= this.screens.PRINT_PREVIEW;
      },
      printToScreen(){
          var data = this.$refs.quotationScreenPrint;
          var myWindow = window.open("", "", "width=600,height=400");
          myWindow.document.write('<html><head>');
          myWindow.document.write('</head><body>')
          myWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
          myWindow.document.write(data.innerHTML);
          myWindow.document.write('</body></html>');
      },
      gotoDetailsScreen(){
          this.showscreen= this.screens.NEW_QUOTATION;
      },
      generateInvoice(){
          let invoice = getInvoiceTemplate();
          invoice.quotation.quoteno = this.curQuote.quotation.quoteno;
          invoice.quotation.quotedt = this.curQuote.quotation.quotedt;
          invoice.quotation.salesPerson =this.curQuote.quotation.salesPerson;
          invoice.invoice.invNumber=nextInvoiceNumber();
          invoice.invoice.invDate="";
          invoice.invoice.salesPerson=this.curQuote.quotation.salesPerson;
          invoice.invoice.tTaxAmountInWords="";
          invoice.invoice.tAmountInWords="";
          
          invoice.customer = this.curQuote.customer;
          invoice.contacts = this.curQuote.contacts;
          invoice.items = this.curQuote.items;
          addInvoice(invoice); 

      }
      
    }
}
</script>
