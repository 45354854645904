<template>
  <div>
    <div class=" m-2 pt-2 pb-2 bg-secondary text-light">Propspect Management</div>
    <div class="row">
        <div class="col">
            <div class="input-group">
            <div class="w-50"><input type="text" class="form-control form-control-sm" placeholder="Enter prospect's Name" aria-label="City"></div>
            <button class="btn btn-outline-primary btn-sm ms-2 " v-on:click="listProspectsHandler">Get</button>
            <button class="btn btn-outline-primary btn-sm ms-2"  v-on:click="newProspect">New Prospect</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div v-if="showscreen==screens.SEARCH_RESULTS" class="mt-2"> 
            <div class="table-responsive">
              <table class="table table-striped table-hover caption-top">
                  <caption>Search Reasults</caption>
                  <thead>
                      <tr>
                          <th>Name</th>
                          <th>Sales Person</th>
                          <th>Requirement</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(prospect,index) in prospects" v-bind:key="index">
                          <td class="text-start">{{prospect.name}}</td>
                          <td class="text-start">{{prospect.salesPerson}}</td>
                          <td class="text-start">{{prospect.requirement}}</td>
                          <td>
                                <button type="button" class="ms-2 btn btn-sm btn-outline-primary" v-on:click="showDetails(prospect)"><i class="bi bi-card-text"></i></button>
                                <!--<button type="button" class="ms-2 btn btn-sm btn-outline-primary"><i class="bi bi-pencil-square"></i></button> -->
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
        </div>
        <div v-if="showscreen==screens.NEW_PROSPECT" class="mt-2"> 
            <div class="card">
                 <div class="card-header">
                     <h3>New Prospect</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <!-- 1st row Prospect,salesperson -->
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Name</span>
                                    <input type="text" class="form-control" v-model="curProspect.name" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Sales Person</span>
                                    <input type="text" class="form-control" v-model="curProspect.salesPerson" placeholder="Sales Person">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Address</span>
                                    <textarea class="form-control" v-model="curProspect.address" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <p class="text-start"><strong> Select Product </strong></p>
                            <selectProdComponent :showDetails=false 
                                :showCommandButtons=false
                                @product-selected="successProductSelection">
                            </selectProdComponent>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2" v-on:click="addProspect">Save</button>
                     <button class="btn btn-primary btn-sm ms-2" v-on:click="gotoHome" >Cancel</button>
                 </div>
            </div>
        </div>
        <div v-if="showscreen==screens.PROSPECT_DETAILS" class="mt-2"> 
            <div class="card">
                 <div class="card-header">
                     <h3>{{curProspect.name}} Details</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <!-- 1st row Prospect,salesperson -->
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Name</span>
                                    <input type="text" class="form-control" v-model="curProspect.name" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Sales Person</span>
                                    <input type="text" class="form-control" v-model="curProspect.salesPerson" placeholder="Sales Person">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="input-group">
                                    <span class="input-group-text" > Address</span>
                                    <textarea class="form-control" v-model="curProspect.address" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="table-responsive">
                                    <form>
                                    <table class="table caption-top table-sm">
                                        <caption>Contacts</caption>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <td>Designation</td>
                                                <td>Phone</td>
                                                <td>Email</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            <tr class="text-start" v-for="contact in curProspect.contacts" v-bind:key="contact.id">
                                                <td>{{contact.name}}</td>
                                                <td>{{contact.designation}}</td>
                                                <td>{{contact.phoneno}}</td>
                                                <td>{{contact.email}}</td>
                                                <td><button v-on:click="deleteContactHandler(contact)" class="btn btn-outline-primary btn-sm"><i class="bi bi-person-dash"></i></button></td>
                                            </tr>
                                            
                                        </tbody>
                                        <tfoot >
                                            <button v-on:click="addContactToProspectHandler" class="btn  btn-outline-primary btn-sm ms-2 mt-2"><i class="bi bi-person-plus"></i></button>
                                        </tfoot>
                                    </table>
                                    </form>
                                </div>
                            </div>
                            
                            <div class="col-6">
                                <div class="table-responsive">
                                    <table class="table caption-top table-sm">
                                        <caption>Products Interested</caption>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <td>Part No</td>
                                                <td>Qty</td>
                                                <td>Price</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-start" v-for="(item,idx) in curProspect.items" v-bind:key="idx">
                                                <td><input type="checkbox" v-model="productForQuotation" :value="idx">{{item.name}}</td>
                                                <td>{{item.partno}}</td>
                                                <td>{{item.qty}}</td>
                                                <td>{{item.mrp}}</td>
                                                <td><button class="btn btn-outline-primary btn-sm" v-on:click="addProductToProspect"><i class="bi bi-trash"></i></button></td>
                                            </tr>
                                        </tbody>
                                        <tfoot >
                                            <button v-on:click="addProductToProspect" class="btn btn-outline-primary btn-sm ms-2 mt-2"><i class="bi bi-plus-lg"></i><i class="bi bi-box"></i></button>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2" v-on:click="saveProspectDetails">Save</button>
                     <button class="btn btn-primary btn-sm ms-2" v-on:click="gotoSearchResults">Cancel</button>
                     <button class="btn btn-primary btn-sm ms-2" v-on:click="prepareQuotation">Prepare Quotation</button>
                     <button v-if="curProspect.isCustomer==false" class="btn btn-primary btn-sm ms-2" v-on:click="makeCustomer">To Customer</button>
                 </div>
            </div>
        </div>
        <div v-if="showscreen==screens.ADD_CONTACT" class="mt-2"> 
           <newContactComponent @save-record="contactSavedEventHandler" @cancel-record="contactCancelEventHandler" v-bind:title="'Prospect Contact Management'"> </newContactComponent>
        </div>
        <div v-if="showscreen==screens.DELETE_CONTACT" class="mt-2"> 
           <newContactComponent v-bind:isDelete="true" v-bind:contact="selectedContact" @delete-record="contactDeletedEventHandler" @cancel-record="contactCancelEventHandler" v-bind:title="'Prospect Contact Management'"> </newContactComponent>
        </div>
        <div v-if="showscreen==screens.ADD_PRODUCT" class="mt-2">
            <div class="card">
                <div class="card-header">
                    Prospect 
                </div>
                <div class="card-body">
                    <p class="text-start">Select product of interest</p>
                    <selectProdComponent :showDetails=false 
                        :showCommandButtons=false
                        @product-selected="productSelectedEventHandler">
                    </selectProdComponent>
                </div>
                <div class="card-footer">
                    <button class="btn btn-outline-primary btn-sm ms-2" v-on:click="insertProductToItemslist">Save</button>
                    <button class="btn btn-outline-primary btn-sm ms-2" v-on:click="gotoProspectDetails">Cancel</button>
                </div>
            </div>
             
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import newContactComponent from '@/components/newContact.vue'
import selectProdComponent from '@/components/productSelection.vue'
import {listProspects,getProspectTemplate,addProspect,updateProspect,addContactToProspect,
        listContactsOfProspect,deleteContactFromProspect} from '@/services/prospectService.js'
import {getQuotationTemplate,addQuotation,nextQuotationNumber} from '@/services/quotationService.js'
import {getCustomerTemplate,addCustomer} from '@/services/customerService.js'

export default {
    name: 'ProspectMgmt',
    components:{
        newContactComponent,
        selectProdComponent
    },
    inject:['collections'],
    data(){
      return {
        showscreen:0,
        subscreen:1,
        screens:{PROSPECT_HOME:0,SEARCH_RESULTS:1,NEW_PROSPECT:2,PROSPECT_DETAILS:3,ADD_CONTACT:4,ADD_PRODUCT:5,DELETE_CONTACT:6},
        prospects:[],
        selectedCat:"",
        selectedMake:"",
        prodMake:"",
        prodNames:"",
        partNumbers:"",
        selectedProdName:"",
        selectedPartNumber:"",
        selectedProductDesc:"",
        prodSelected:{},
        productForQuotation:[],
        selectedContact:{},
        curProspect:{name:"",salesPerson:"",requirement:"",address:"",comment:"",
            contacts:[{name:"contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
            items:[
                {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
                "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
            ]},
        }
        
        
    },
    created(){
        this.prospects=[
        {
            id:"",name:"Hexaware",salesPerson:"Vijay",requirement:"",address:"",comment:"",
            contacts:[{name:"contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
            items:[
                {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
                "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
            ]
        },
        {
            id:"",name:"Wipro",salesPerson:"Param",requirement:"",address:"",comment:"",
            contacts:[{name:"Wipro contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
            items:[
                {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
                "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
            ]
        },
        {
            id:"",name:"TCS",salesPerson:"Jitendra",requirement:"",address:"",comment:"",
            contacts:[{name:"TCS contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
            items:[
                {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
                "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
            ]
        },
        {
            id:"",name:"TCS-Chennai",salesPerson:"Krishnamurthy",requirement:"",address:"",comment:"",
            contacts:[{name:"TCS-Chennai contact1",designation:"Design1",phoneno:"1234567890",email:"test@gmail.com"}],
            items:[
                {"cat":"ENTERPRISE","make":"DELL","name":"Inspiron 14 Laptop 8GB","partno":"icc-c782512win8",
                "desc":"","sgst":"18","cgst":"18","igst":"18","hsn":"84713010","mrp":"72,489.98","qty":1,"price":""}
            ]
        },
        ];

    },
    watch:{
        products:function(){
            if(this.products.length==1){
                this.selectedProductDesc = this.products[0].desc;
                this.selectedPartNumber = this.products[0].partno;
            }else{
                this.selectedProductDesc = "";
            }
        }
    },
    methods:{
      listProspectsHandler:function(){
          listProspects().then(data =>{
              this.prospects = data;
          });
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      newProspect:function(){
          this.curProspect= getProspectTemplate();
          this.showscreen= this.screens.NEW_PROSPECT;
      },
      addProspect:function(){
          addProspect(this.curProspect);
          this.showscreen= this.screens.PROSPECT_HOME;
      },
      gotoHome:function(){
          this.showscreen= this.screens.PROSPECT_HOME;
      },
      showDetails:function(prospect){
          this.curProspect = prospect;
          this.showscreen= this.screens.PROSPECT_DETAILS;
      },
      addContactToProspectHandler:function(){
          this.showscreen=this.screens.ADD_CONTACT;
      },
      deleteContactHandler:function(contact){
          this.selectedContact = contact;
          this.showscreen= this.screens.DELETE_CONTACT;
      },
      successProductSelection:function(event){
          this.curProspect.items[0]=event;
      },
      addProductToProspect:function(){
          this.showscreen=this.screens.ADD_PRODUCT;
      },
      saveProspectDetails:function(){
          //@ todo save the updated details
          this.gotoSearchResults();
      },
      gotoSearchResults:function(){
          this.showscreen=this.screens.SEARCH_RESULTS;
      },
      contactSavedEventHandler:function(event){
         //@ todo add contact info to the prospect 
         var data = {
            "prospectID": "4",
            "name":"Annadorai",
            "designation":"MD",
            "phoneno":"1234554321",
            "email":"dorai@anna.com"
        };
         data.prospectID = this.curProspect.id;
         data.name=event.name;
         data.designation=event.designation;
         data.phoneno=event.phoneno;
         data.email=event.email;
         addContactToProspect(data);
         var query={"prospectID": "4"};
         query.prospectID = this.curProspect.id;
         listContactsOfProspect(query).then(
             data=>{
                 this.curProspect.contacts = data;
             }
         );
         //this.curProspect.contacts.push(event);
         console.log(event);
         this.showscreen= this.screens.PROSPECT_DETAILS;
      },
      contactDeletedEventHandler:function(contact){
          var query={
                "prospectID":"4",
                "contactID" : "4"
            };
          query.prospectID=contact.prospect_id;
          query.contactID=contact.id;
          deleteContactFromProspect(query).then(resp => console.log(resp));
          this.showscreen= this.screens.PROSPECT_DETAILS;
      },
      contactCancelEventHandler:function(){
          this.showscreen= this.screens.PROSPECT_DETAILS;
      },    
      gotoProspectDetails:function(){
          // return to details screen without adding product to the list
          this.showscreen= this.screens.PROSPECT_DETAILS;
      },
      insertProductToItemslist:function(){
          this.curProspect.items.push(this.prodSelected);
          this.showscreen= this.screens.PROSPECT_DETAILS;
          this.prodSelected={};
      },
      productSelectedEventHandler:function(event){
          //capture the product details, save when user click on save button
          this.prodSelected=event;
      },
      prepareQuotation(){
          if(this.productForQuotation.length<1){
              alert("Please select atleast a product from products");
              return;
          }
          let prods =[];
          for(let i=0;i<this.productForQuotation.length;i++){
              prods.push(this.productForQuotation[i]);
          }
          let newQuote = getQuotationTemplate();
          newQuote.quotation.quoteno= nextQuotationNumber();
          newQuote.customer.name = this.curProspect.name;
          newQuote.customer.billAddress=this.curProspect.address;
          newQuote.quotation.salesPerson=this.curProspect.salesPerson;
          newQuote.contacts=this.curProspect.contacts;
          newQuote.items=this.prods;
          addQuotation(newQuote);
          this.showscreen=this.screens.SEARCH_RESULTS;
      },
      makeCustomer:function(){
          let newCustomer = getCustomerTemplate();
          newCustomer.name = this.curProspect.name;
          newCustomer.address =this.curProspect.address;
          addCustomer(newCustomer);
          this.curProspect.isCustomer=true;
          updateProspect(this.curProspect);
          this.gotoSearchResults();
      }
    
    }
}
</script>
